
h1[data-v-bb314e] {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: fit-content;
    font-size: 1.25rem;
    line-height: 1.75rem
}
h1[data-v-bb314e]::before {
    position: relative;
    left: -0.875rem;
    top: -1.75rem;
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-left-width: 1rem;
    border-right-width: 1rem;
    border-bottom-width: 1.73rem;
    border-left-color: transparent;
    border-right-color: transparent;
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-bottom-color: rgb(34 197 94 / var(--tw-border-opacity, 1))
}
h1[data-v-bb314e]::after {
    position: absolute;
    left: -0.25rem;
    top: 0px;
    --tw-scale-x: 1.35;
    --tw-scale-y: 1.35;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-left-width: 1rem;
    border-right-width: 1rem;
    border-bottom-width: 1.73rem;
    border-left-color: transparent;
    border-right-color: transparent;
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-bottom-color: rgb(21 128 61 / var(--tw-border-opacity, 1))
}
section[data-v-bb314e] {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: fit-content
}
.events[data-v-bb314e] {
    display: grid;
    column-gap: 1rem
}
.events > p[data-v-bb314e] {
    grid-column-start: 2
}
img[data-v-bb314e] {
    aspect-ratio: 16 / 9;
    object-fit: cover
}
caption[data-v-bb314e] {
    margin-left: 1rem;
    margin-right: 1rem;
    display: block;
    padding-left: 1rem;
    text-align: justify;
    text-indent: -1rem
}
caption[data-v-bb314e]::before {
    --tw-content: "▲";
    content: var(--tw-content)
}
