
menu[data-v-61ee8d], button[data-v-61ee8d] {
  position: fixed;
  left: 1rem;
  z-index: 10;
}
menu[data-v-61ee8d] {
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
menu > li[data-v-61ee8d]:not(:last-child) {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}
.link[data-v-61ee8d] {
  display: flex;
  gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.link[data-v-61ee8d]::before {
  border-left-width: 4px;
  content: var(--tw-content);
  border-color: transparent;
}
.link.active[data-v-61ee8d]::before {
  height: 0px;
  align-self: center;
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
menu.v-enter-from[data-v-61ee8d], menu.v-leave-to[data-v-61ee8d] {
  --tw-translate-y: 100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
menu.v-enter-active[data-v-61ee8d], menu.v-leave-active[data-v-61ee8d] {
  transform-origin: bottom;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
menu.v-enter-to[data-v-61ee8d], menu.v-leave-from[data-v-61ee8d] {
  --tw-translate-y: 0px;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
menu.next-leave-from[data-v-61ee8d] {
  --tw-translate-y: 0px;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
menu.next-leave-active[data-v-61ee8d] {
  transform-origin: top;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
menu.next-leave-to[data-v-61ee8d] {
  --tw-translate-y: -100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
button[data-v-61ee8d] {
  bottom: 0.5rem;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
button[data-v-61ee8d]::after {
  content: "▲";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 3.5rem;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}
button[data-v-61ee8d]::after {
  animation: bounce 1s infinite;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}
button.v-leave-from[data-v-61ee8d], button.v-enter-to[data-v-61ee8d] {
  --tw-translate-y: 0px;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
button.v-leave-active[data-v-61ee8d], button.v-enter-active[data-v-61ee8d] {
  transform-origin: top;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
button.v-leave-to[data-v-61ee8d], button.v-enter-from[data-v-61ee8d] {
  --tw-translate-y: -100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
button.next-enter-from[data-v-61ee8d] {
  --tw-translate-y: 100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
button.next-enter-active[data-v-61ee8d] {
  transform-origin: bottom;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
button.next-enter-to[data-v-61ee8d] {
  --tw-translate-y: 0px;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
nav[data-v-61ee8d] {
  position: fixed;
  inset: 0px;
}
