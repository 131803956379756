menu[data-v-61ee8d], button[data-v-61ee8d] {
  z-index: 10;
  position: fixed;
  left: 1rem;
}

menu[data-v-61ee8d] {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(148 163 184 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  flex-direction: column;
  display: flex;
  bottom: 1rem;
}

menu > li[data-v-61ee8d]:not(:last-child) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}

.link[data-v-61ee8d] {
  gap: .5rem;
  padding: .375rem 1rem;
  display: flex;
}

.link[data-v-61ee8d]:before {
  content: var(--tw-content);
  border-color: #0000;
  border-left-width: 4px;
}

.link.active[data-v-61ee8d]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  align-self: center;
  height: 0;
}

menu.v-enter-from[data-v-61ee8d], menu.v-leave-to[data-v-61ee8d] {
  --tw-translate-y: 100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

menu.v-enter-active[data-v-61ee8d], menu.v-leave-active[data-v-61ee8d] {
  transform-origin: bottom;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

menu.v-enter-to[data-v-61ee8d], menu.v-leave-from[data-v-61ee8d], menu.next-leave-from[data-v-61ee8d] {
  --tw-translate-y: 0px;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

menu.next-leave-active[data-v-61ee8d] {
  transform-origin: top;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

menu.next-leave-to[data-v-61ee8d] {
  --tw-translate-y: -100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

button[data-v-61ee8d] {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 3.5rem;
  height: 3.5rem;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  bottom: .5rem;
}

button[data-v-61ee8d]:after {
  content: "▲";
  position: absolute;
  bottom: 3.5rem;
  left: 0;
  right: 0;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

button[data-v-61ee8d]:after {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
  animation: 1s infinite bounce;
}

button.v-leave-from[data-v-61ee8d], button.v-enter-to[data-v-61ee8d] {
  --tw-translate-y: 0px;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

button.v-leave-active[data-v-61ee8d], button.v-enter-active[data-v-61ee8d] {
  transform-origin: top;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

button.v-leave-to[data-v-61ee8d], button.v-enter-from[data-v-61ee8d] {
  --tw-translate-y: -100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

button.next-enter-from[data-v-61ee8d] {
  --tw-translate-y: 100%;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

button.next-enter-active[data-v-61ee8d] {
  transform-origin: bottom;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

button.next-enter-to[data-v-61ee8d] {
  --tw-translate-y: 0px;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

nav[data-v-61ee8d] {
  position: fixed;
  inset: 0;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

:root {
  font-weight: 300;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.relative {
  position: relative;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-dvh {
  height: 100dvh;
}

.w-fit {
  width: fit-content;
}

.grow {
  flex-grow: 1;
}

.flex-col {
  flex-direction: column;
}

.border-t {
  border-top-width: 1px;
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.text-justify {
  text-align: justify;
}

.-indent-4 {
  text-indent: -1rem;
}

.indent-4 {
  text-indent: 1rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  text-align: justify;
  flex-direction: column;
  height: 100dvh;
  display: flex;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:relative:before {
  content: var(--tw-content);
  position: relative;
}

.before\:-left-12:before {
  content: var(--tw-content);
  left: -3rem;
}

.before\:-left-3\.5:before {
  content: var(--tw-content);
  left: -.875rem;
}

.before\:-left-6:before {
  content: var(--tw-content);
  left: -1.5rem;
}

.before\:-top-1:before {
  content: var(--tw-content);
  top: -.25rem;
}

.before\:-top-7:before {
  content: var(--tw-content);
  top: -1.75rem;
}

.before\:right-3\.5:before {
  content: var(--tw-content);
  right: .875rem;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-0\.5:before {
  content: var(--tw-content);
  top: .125rem;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-8:before {
  content: var(--tw-content);
  height: 2rem;
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:border-x-\[\.5rem\]:before {
  content: var(--tw-content);
  border-left-width: .5rem;
  border-right-width: .5rem;
}

.before\:border-x-\[0\.58rem\]:before {
  content: var(--tw-content);
  border-left-width: .58rem;
  border-right-width: .58rem;
}

.before\:border-x-\[1rem\]:before {
  content: var(--tw-content);
  border-left-width: 1rem;
  border-right-width: 1rem;
}

.before\:border-b-\[\.87rem\]:before {
  content: var(--tw-content);
  border-bottom-width: .87rem;
}

.before\:border-b-\[1\.73rem\]:before {
  content: var(--tw-content);
  border-bottom-width: 1.73rem;
}

.before\:border-b-\[1rem\]:before {
  content: var(--tw-content);
  border-bottom-width: 1rem;
}

.before\:border-x-transparent:before {
  content: var(--tw-content);
  border-left-color: #0000;
  border-right-color: #0000;
}

.before\:border-b-green-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
}

.before\:bg-gradient-to-b:before {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.before\:from-lime-400:before {
  content: var(--tw-content);
  --tw-gradient-from: #a3e635 var(--tw-gradient-from-position);
  --tw-gradient-to: #a3e63500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:to-transparent:before {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.before\:opacity-25:before {
  content: var(--tw-content);
  opacity: .25;
}

.before\:content-\[\"▲\"\]:before {
  --tw-content: "▲";
  content: var(--tw-content);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:sticky:after {
  content: var(--tw-content);
  position: sticky;
}

.after\:-left-1:after {
  content: var(--tw-content);
  left: -.25rem;
}

.after\:-left-5:after {
  content: var(--tw-content);
  left: -1.25rem;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:bottom-1:after {
  content: var(--tw-content);
  bottom: .25rem;
}

.after\:right-2:after {
  content: var(--tw-content);
  right: .5rem;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:top-1:after {
  content: var(--tw-content);
  top: .25rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-20:after {
  content: var(--tw-content);
  height: 5rem;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:scale-110:after {
  content: var(--tw-content);
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:scale-\[1\.35\]:after {
  content: var(--tw-content);
  --tw-scale-x: 1.35;
  --tw-scale-y: 1.35;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:border-x-\[\.5rem\]:after {
  content: var(--tw-content);
  border-left-width: .5rem;
  border-right-width: .5rem;
}

.after\:border-x-\[0\.58rem\]:after {
  content: var(--tw-content);
  border-left-width: .58rem;
  border-right-width: .58rem;
}

.after\:border-x-\[1rem\]:after {
  content: var(--tw-content);
  border-left-width: 1rem;
  border-right-width: 1rem;
}

.after\:border-b-\[\.87rem\]:after {
  content: var(--tw-content);
  border-bottom-width: .87rem;
}

.after\:border-b-\[1\.73rem\]:after {
  content: var(--tw-content);
  border-bottom-width: 1.73rem;
}

.after\:border-b-\[1rem\]:after {
  content: var(--tw-content);
  border-bottom-width: 1rem;
}

.after\:border-x-transparent:after {
  content: var(--tw-content);
  border-left-color: #0000;
  border-right-color: #0000;
}

.after\:border-b-green-700:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
}

.after\:bg-gradient-to-t:after {
  content: var(--tw-content);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.after\:from-gray-100:after {
  content: var(--tw-content);
  --tw-gradient-from: #f3f4f6 var(--tw-gradient-from-position);
  --tw-gradient-to: #f3f4f600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:to-transparent:after {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.after\:opacity-90:after {
  content: var(--tw-content);
  opacity: .9;
}

main[data-v-d59495] {
  flex-grow: 1;
  overflow: auto;
}

main.gradient[data-v-d59495]:after {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #f3f4f6 var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  opacity: .9;
  width: 100%;
  height: 5rem;
  display: block;
  position: sticky;
  bottom: 0;
}

main.gradient[data-v-d59495]:before {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #a3e635 var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  opacity: .25;
  width: 100%;
  height: 2rem;
  display: block;
  top: 0;
}

footer[data-v-d59495] {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(190 242 100 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .875rem;
  line-height: 1.25rem;
  position: relative;
}

footer[data-v-d59495]:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-width: 1rem;
  border-left-width: .58rem;
  border-left-color: #0000;
  border-right-width: .58rem;
  border-right-color: #0000;
  border-bottom-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
  position: absolute;
  bottom: .5rem;
  right: .875rem;
}

footer[data-v-d59495]:after {
  transform-origin: 100% 100%;
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-width: 1rem;
  border-left-width: .58rem;
  border-left-color: #0000;
  border-right-width: .58rem;
  border-right-color: #0000;
  border-bottom-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
  position: absolute;
  bottom: .25rem;
  right: .5rem;
}

footer > .link[data-v-d59495] {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

h1[data-v-bb314e] {
  width: fit-content;
  margin: 2rem auto;
  font-size: 1.25rem;
  line-height: 1.75rem;
  position: relative;
}

h1[data-v-bb314e]:before {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-width: 1.73rem;
  border-left-width: 1rem;
  border-left-color: #0000;
  border-right-width: 1rem;
  border-right-color: #0000;
  border-bottom-color: rgb(34 197 94 / var(--tw-border-opacity, 1));
  position: relative;
  top: -1.75rem;
  left: -.875rem;
}

h1[data-v-bb314e]:after {
  --tw-scale-x: 1.35;
  --tw-scale-y: 1.35;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-width: 1.73rem;
  border-left-width: 1rem;
  border-left-color: #0000;
  border-right-width: 1rem;
  border-right-color: #0000;
  border-bottom-color: rgb(21 128 61 / var(--tw-border-opacity, 1));
  position: absolute;
  top: 0;
  left: -.25rem;
}

section[data-v-bb314e] {
  width: fit-content;
  margin: 1rem auto;
}

.events[data-v-bb314e] {
  column-gap: 1rem;
  display: grid;
}

.events > p[data-v-bb314e] {
  grid-column-start: 2;
}

img[data-v-bb314e] {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

caption[data-v-bb314e] {
  text-align: justify;
  text-indent: -1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1rem;
  display: block;
}

caption[data-v-bb314e]:before {
  --tw-content: "▲";
  content: var(--tw-content);
}
/*# sourceMappingURL=index.ec7ffb04.css.map */
